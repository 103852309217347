import React, { useState, useRef, useEffect } from "react";
import "../styles/create_issue.css";
import { useDispatch, useSelector } from 'react-redux'
import { CreateIssue, issuesSelector } from '../slices/issues'
import confirm, { alert } from "react-alert-confirm";
import { useNavigate } from 'react-router-dom';
import { fetchIssueDetails, StoreApiCallCount, StoreIsRunning, issueCreatedStatus } from '../slices/issues'
import { fetchIssueListUsingToken } from '../slices/IssueList'	
import { BlankLoader } from '../pages/BlankLoadingPage';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Tooltip, Typography, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import { protectedResources } from "../authConfig";
import SessionPoller from '../components/sessionPoller';

export const Form = () => {
  const req = {
    scopes: protectedResources.apiGetIssues.scopes
  }
  const [disable, setDisable] = useState(false);
  const [fileError, setFileError] = useState(false);
  const dispatch = useDispatch();
  const disp = useDispatch();
  const [ProductValue, setProductValue] = useState('');
  const [RequestTypeValue, setRequestTypeValue] = useState('');
  const [PriorityValue, setPriorityValue] = useState('');
  const [ServiceRequestTypeValue, setServiceRequestTypeValue] = useState('');
  const [IncidentTypeValue, setIncidentTypeValue] = useState('');
  const [files, setFiles] = useState();
  const { issueCreated, createdIssueId, apiResponseStatus,hasErrors } = useSelector(issuesSelector);
  const [emails, setEmails] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const RefProduct = useRef(null);
  const RefCcInput = useRef(null);
  const RefIncidentType = useRef(null);
  const RefServiceRequestType = useRef(null);
  const RefIssueDescription = useRef(null);
  const RefIssueTitle = useRef(null);
  const RefFileInput = useRef(null);
  const RefRequestType = useRef(null);
  const RefPriority = useRef(null);
  const RefSubmit = useRef(null);
  const [formData, setFormData] = useState({});
  const [fileErrorMsg, setFileErrorMsg] = useState();
  const [isAttachment, setIsAttachment] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  let author = "";
  const { instance } = useMsal();
  //const [displayCC, setDisplayCC] = useState(false);

  const submitForm = (e) => {
    setCount(prevCount => prevCount + 1);
   // console.log(formData);
    dispatch(CreateIssue(formData, files));
    e.preventDefault();
    setDisable(true);
    setFiles(null);
  }

  const onInputChange = (e) => {
    let { name, value } = e.target;
    if (e.target.name === "request_type") {
      setRequestTypeValue(e.target.value);
    }
    if (e.target.name === "priority") {
      setPriorityValue(e.target.value);
    }
    if (e.target.name === 'service_request_type') {
      setServiceRequestTypeValue(e.target.value);
    }
    if (e.target.name === 'incident_type') {
      setIncidentTypeValue(e.target.value);
    }
    if (e.target.name === 'cc') {
      setEmails(e.target.value);
      setIsEmailValid(validateEmails(e.target.value));
    }
    if (e.target.name === 'product') {
      setProductValue(e.target.value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const fileupload = (e) => {
    if (e.target.files.length > 0) {
      setCount(prevCount => prevCount + 1);
      setIsAttachment(true);
      if (e.target.files[0].name.length > 70 || e.target.files[0].size > 5242880) {
        e.target.files[0].name.length > 70 ? setFileErrorMsg("File name is too long, file name should have less than 70 characters") : setFileErrorMsg("File Size is too large, File size should be less than 5 MB")
        e.target.value = '';
        setFileError(true);

      } else {
        setFiles(e.target.files[0]);
        setFileError(false);
       
      }
    }
  }

  useEffect(() => {

    if (fileError) { openConfirm(); }
    //console.log(apiResponseStatus);
    if (issueCreated) {
      successCreateIssue();
    } else if (apiResponseStatus.length > 0) {
      failCreateIssue()
    }
    // eslint-disable-next-line
  }, [count, dispatch, issueCreated])

  const openConfirm = async () => {
    await alert(fileErrorMsg);
   // console.log('ok');
    setFileError(false);
    setIsAttachment(false);
  };

  const successCreateIssue = async () => {
    await confirm({
      footer(dispatch) {
        return (
          <div style={{ display: 'flex' }}>
            <h4>Issue Created Successfully!!</h4>
            <button style={{ width: '100%', marginLeft: '2em' }} onClick={() => {
              dispatch(fetchIssueDetails(createdIssueId, author));
              setShowLoader(true);
              dispatch(StoreApiCallCount(1));
              dispatch(StoreIsRunning(true));
              setTimeout(() => {
                setShowLoader(false);
                dispatch(fetchIssueListUsingToken());
                navigate(`/Getissues?id=${createdIssueId}`, { replace: true });
              }, 4000)
            }}
              type="primary"> Open Created Issue </button>
            <button style={{ backgroundColor: "rgba(92,103,112,255)", marginLeft: '1em', width: '100%' }} onClick={() => {
              RefIssueTitle.current.value = "";
              RefIssueDescription.current.value = "";
              RefRequestType.current.value = "";
              RefPriority.current.value = "";
              RefFileInput.current.value = "";
              RefProduct.current.value = "";
              setDisable(false);
              dispatch("hello");
              disp(issueCreatedStatus());
              console.log("executed");
            }} type="primary"> Close </button>
          </div>
        );
      }
    });
  }

  const failCreateIssue = async () => {
    await alert(apiResponseStatus);

  };

  function removeAttachment() {
    RefFileInput.current.value = "";
    setFiles(null);
    setIsAttachment(false);
    const fileInput = RefFileInput.current;
    fileInput.value = null;
  }

  const validateEmails = (input) => {
    if (input === '') {
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailsArray = input.split(';');
    for (let i = 0; i < emailsArray.length; i++) {
      const trimmedEmail = emailsArray[i].trim();
      if (!emailRegex.test(trimmedEmail)) {
        return false;
      }
      if (trimmedEmail !== emailsArray[i]) {
        // The email address has extra whitespace characters
        return false;
      }
    }
    return true;
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
  ))(({ theme }) => ({
    backgroundColor: 'white',
    color: 'gray',
    fontSize: 'normal',
    padding: '8px',
    borderRadius: '5px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
  }));

  const tooltipTextStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: 'gray'
  };

  function InfoIcon({ tooltipText }) {
    return (
      <StyledTooltip title={tooltipText} arrow>
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </StyledTooltip>
    );
  }

  if(hasErrors){
    instance.loginRedirect(req);
  }
 
  return (
    <>
    <SessionPoller />
    <AuthenticatedTemplate>

      <div className="column-createIssue">
        {showLoader ? <BlankLoader /> : ""}
        <div style={{ width: '50vw', margin: 'auto', paddingTop: '0vh' }}>

          <form onSubmit={submitForm} style={{ marginTop: '2vh' }}>
            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Product</strong></p>
              <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'x-large' }}><sup>&nbsp;*</sup></p>
            </div>
            <select ref={RefProduct} style={{ width: '100%', height: 'auto', borderColor: '#e4e6e8', border: '3px solid #e4e6e8', borderRadius: '8px', padding: '1em' }}
              required value={ProductValue} onChange={onInputChange} name="product" disabled={disable}>
              <option value=""> -- select an option -- </option>
              <option value="Promo(RetailerIQ)">Promo(RetailerIQ)</option>
              <option value="Quotient Media Platform (QMP)">Quotient Media Platform (QMP)</option>
              <option value="Quotient DOOH Platform (QDOOH)">Quotient DOOH Platform (QDOOH)</option>
              <option value="National Rebates">National Rebates</option>
              <option value="Sponsored Search (Elevaate)">Sponsored Search (Elevaate)</option>
              <option value="Media - MoCA">Media - MoCA</option>
              <option value="Media - Engage">Media - Engage</option>
              <option value="Media - Quotient Social">Media - Quotient Social</option>
              <option value="Platform (Muse)">Platform (Muse)</option>
              <option value="Quotient Business Support">Quotient Business Support</option>
              <option value="Continuity">Continuity</option>
              <option value="Personalised Email Marketing">Personalised Email Marketing</option>
              <option value="Microsites">Microsites</option>
              <option value="Helpshift">Helpshift</option>
              <option value="Alcohol Rebate">Alcohol Rebate</option>
              <option value="Text2Rebate">Text2Rebate</option>
              <option value="Print at Home">Print at Home</option>
              <option value="Others">Others</option>
            </select>

            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Request Type</strong></p>
              <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'x-large' }}><sup>&nbsp;*</sup></p>
            </div>

            <select ref={RefRequestType} style={{ width: '100%', height: 'auto', borderColor: '#e4e6e8', border: '3px solid #e4e6e8', borderRadius: '8px', padding: '1em' }}
              required value={RequestTypeValue} onChange={onInputChange} name="request_type" disabled={disable}>
              <option value=""> -- select an option -- </option>
              <option value='Incident'>Incident</option>
              <option value='Service Request'>Service Request</option>
              <option value='Information'>Information</option>
            </select>
            {
              RequestTypeValue === 'Incident' ?
                <>
                  <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
                    <p><strong>Incident Type</strong></p>
                  </div>
                  <select ref={RefIncidentType} style={{ width: '100%', height: 'auto', borderColor: '#e4e6e8', border: '3px solid #e4e6e8', borderRadius: '8px', padding: '1em' }}
                    value={IncidentTypeValue} onChange={onInputChange} name="incident_type" disabled={disable}>
                    <option value=""> -- select an option -- </option>
                    <option value='Offer Issue'>Offer Issue</option>
                    <option value='Redemption Issue'>Redemption Issue</option>
                    <option value='Activation Issue'>Activation Issue</option>
                    <option value='User Specific'>User Specific</option>
                    <option value='Platform Issue'>Platform Issue</option>
                    <option value='Access Request'>Access Request</option>
                    <option value='Feeds Related'>Feeds Related</option>
                    <option value='API Related'>API Related</option>
                    <option value='Cert Related'>Cert Related</option>
                    <option value='Clearing House'>Clearing House</option>
                    <option value='Other'>Others</option>
                  </select>
                </>
                : RequestTypeValue === 'Service Request' ?
                  <>
                    <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
                      <p><strong>Service Request Type</strong></p>
                    </div>
                    <select ref={RefServiceRequestType} style={{ width: '100%', height: 'auto', borderColor: '#e4e6e8', border: '3px solid #e4e6e8', borderRadius: '8px', padding: '1em' }}
                      value={ServiceRequestTypeValue} onChange={onInputChange} name="service_request_type" disabled={disable}>
                      <option value=""> -- select an option -- </option>
                      <option value='Offer Update'>Offer Update</option>
                      <option value='Offer Creation'>Offer Creation</option>
                      <option value='UPC'>UPC</option>
                      <option value='Reports'>Reports</option>
                      <option value='User Update'>User Update</option>
                      <option value='Cashback related'>Cashback related</option>
                      <option value='User Creation'>User Creation</option>
                      <option value='IP Allow/Block List'>IP Allow/Block List</option>
                      <option value='Ingestion Related'>Ingestion Related</option>
                      <option value='Other'>Others</option>
                    </select>
                  </>
                  : ""
            }
            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Priority</strong></p>
              <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'x-large' }}><sup>&nbsp;*</sup></p>
            </div>

            <select ref={RefPriority} style={{ width: '100%', height: 'auto', borderColor: '#e4e6e8', border: '3px solid #e4e6e8', borderRadius: '8px', padding: '1em' }}
              required value={PriorityValue}
              onChange={onInputChange}
              name="priority"
              disabled={disable}
            >
            <option value=""> -- select an option -- </option>
              <option value='Urgent'>Urgent</option>
              <option value='High'>High</option>
              <option value='Normal'>Normal</option>
              <option value='Low'>Low</option>
            </select>

            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Issue Title </strong></p>
              <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'x-large' }}><sup>&nbsp;*</sup></p>
            </div>

            <input
              ref={RefIssueTitle}
              //value={issue_title}
              onChange={onInputChange}
              placeholder="Enter Issue Title / Summary"
              type="text"
              name="issue_title"
              required
              disabled={disable}
            />

            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Issue Description</strong></p>
              <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'x-large' }}><sup>&nbsp;*</sup></p>
            </div>

            <textarea
              ref={RefIssueDescription}
              rows="5"
              cols="90"
              onChange={onInputChange}
              placeholder="Enter Issue Description"
              type="text"
              name="issue_description"
              required
              disabled={disable}
            />
            <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
              <p><strong>Attachment</strong>&nbsp;(Optional)</p>
            </div>

            <div style={{ display: "flex", width: '102%' }}>
              <input multiple ref={RefFileInput} type="file" onChange={fileupload} disabled={disable} style={{ marginBottom: '2vh', maxWidth: '105% !important' }} />
              <button style={{ backgroundColor: 'transparent', border: 'none' }} className="close" onClick={removeAttachment} type='button' >
                <svg height="18px" id="Layer_1" style={{ enableBackground: 'new 0 0 512 512', visibility: isAttachment ? 'visible' : 'hidden' }} version="1.1" viewBox="0 0 512 512" width="18px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#69727D" d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" /></svg>
              </button>
            </div>

            <div style={{ display: 'block' }}>
              <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', marginTop: '2vh' }}>
                <p><strong>CC:</strong><InfoIcon tooltipText={<Typography style={tooltipTextStyle} > "Enter semicolon separated Emails ex. mymail@org.com;email2@org.com (No spaces allowed after semicolon)"</Typography>} /></p>
              </div>
              <div style={{ display: "flex" }}>
                <input name='cc' ref={RefCcInput} className={isEmailValid ? '' : 'error'} type="text" value={emails} onChange={onInputChange} disabled={disable} style={{ marginBottom: '2vh' }} />
              </div>
            </div>
            {isEmailValid ? null : <p style={{ fontWeight: 'bolder', color: 'red', fontSize: 'small', textAlign: 'center' }}>Please enter valid email addresses separated by semicolon. ex. mymail@org.com;email2@org.com (No spaces allowed after semicolon) </p>}
            <button className={disable || isEmailValid ? 'SubmitFormButton' : 'DisabledSubmitFormButton'} ref={RefSubmit} type="submit" disabled={disable || !isEmailValid} >Submit</button>

          </form>
        </div>
      </div>
    </AuthenticatedTemplate>
    </>
  );
};





