import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux' 
import { Loader } from "../components/loader";
import { useMsal } from '@azure/msal-react';
import { useRef } from "react";
import { issuesSelector,fetchIssueDetails, StoreApiCallCount,StoreIsRunning} from '../slices/issues';
import { fetchAllIssues } from "../slices/IssueList";
import 'react-alert-confirm/lib/style.css';
//import AlertConfirm from 'react-alert-confirm';	
import { useInterval } from "../components/usePolling";
import { protectedResources } from "../authConfig";
import { FeedbackComment } from "../components/feedback_comment";
import { Conversation } from "../components/LoadConversation";
import { fetchIssueListUsingToken } from '../slices/IssueList'

const IssueDetails = () => {
    const { instance} = useMsal();
    const dispatch = useDispatch()
    const bottomRef = useRef(null);
    const { issues, loading, hasErrors,apiCallCount,isRunning,hasError } = useSelector(issuesSelector);
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    let activeAccount;   
    let email="";
    const [interval]=useState(15000);
    const [count,setCount] = useState(1);
    const [currentIssue,setCurrentIssue] = useState([]);
    const req = {
        scopes: protectedResources.apiGetIssues.scopes
    }
    const [showLoader,setShowLoader] = useState(true);
  
    const [csatSubmitted,setCsatSubmitted] = useState(false);
   
    /******** Get Emial ID of logged In User *********/
    activeAccount = instance.getActiveAccount();
    if(activeAccount && activeAccount.hasOwnProperty('idTokenClaims')){
        if(activeAccount.idTokenClaims.hasOwnProperty('email')){
            email = activeAccount.idTokenClaims.email.toUpperCase();
        }
    }
    
    // const openConfirm = async () => {
    //     const [isOk] = await AlertConfirm('This page was idle, Please, click "OK" to view the recent updates.');
    //     if (isOk) {
    //     console.log('ok');
    //     dispatch(StoreIsRunning(true));
    //     }
    // };
    
    useEffect(() => {
        
        dispatch(fetchIssueDetails(id,email));
        setTimeout(()=>{
            dispatch(fetchAllIssues);
            
            dispatch(fetchIssueDetails(id,email))
        },2000)
    // eslint-disable-next-line
    }, []) 
    

    useEffect(()=>{
        if(count<=0){
            bottomRef.current?.scrollIntoView({behavior: 'auto'}); 
        }
        // eslint-disable-next-line
    },[issues])

    useEffect(()=>{
       setTimeout(()=>{
        bottomRef.current?.scrollIntoView({behavior: 'auto'}); 
       },[2000]);
       
    },[id])

    useEffect(()=>{
        if(issues[0]?.id === parseInt(id)){
            setShowLoader(false);
            setCurrentIssue(issues)
            if(issues[0].hasOwnProperty('feedback_rating')){
                setCsatSubmitted(true);
            }else{
                setCsatSubmitted(false);
            }
        }else{
            setCurrentIssue("");
            setShowLoader(true);
        }    
         // eslint-disable-next-line
    },[issues,id])

    useInterval(async()=>{
        //console.log(count);
       
        if(apiCallCount===1){    
            setCount(0);
            dispatch(StoreApiCallCount(0))
        }          
        setCount(prevcount => prevcount + 1);
        dispatch(fetchIssueListUsingToken());  
        dispatch(fetchIssueDetails(id,email))
    },isRunning ? interval : null );

    useEffect(()=>{
        if(count>16){
            setCount(1);
             dispatch(StoreApiCallCount(0));
             dispatch(StoreIsRunning(false));
            // openConfirm();
         }
         // eslint-disable-next-line
    },[count])
   

    if(hasError){
        instance.loginRedirect(req);
    }

    

    const renderMessages = () => {
        if(showLoader) return <Loader/>
        if (loading) return <Loader/>
        if (hasErrors && !issues) return <p>{localStorage.getItem("Status")}</p>
          return (       
            <>    
                 {  
                        
                        <div>
                            {currentIssue[0] && currentIssue[0].messages.map((m,i) => ( 
                                <div className="conversation-container" key={i} >
                                    { csatSubmitted 
                                        ?   
                                            (
                                                i<currentIssue[0].messages.length-1
                                                ?
                                                    <Conversation message={m} index={i} key={i} /> 
                                                :   "..."
                                            )
                                        :  <Conversation message={m} index={i} key={i} /> 
                                    }
                                    <div ref={bottomRef} />
                                          
                                </div> 
                            ))} 
                                <FeedbackComment />
                            <div ref={bottomRef} />        
                        </div>
                }
             </>             
         );           
        
      }      

      return (
        <section>
           <div className='content'>
            {renderMessages()}
          </div>
        </section>
      )
    }
    
    
export default IssueDetails
