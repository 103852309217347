import React, { useMemo,useState,useEffect } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
//import { Loader } from "./loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const Table = props => {
  const [pending, setPending] = useState(true);
  const zone = moment.tz.guess();  
  const shortcutFormatter = Intl.DateTimeFormat(moment.tz(zone).format("z"), {
       year: 'numeric',
       month: '2-digit',
       day: '2-digit',
       hour: '2-digit',
       minute: '2-digit',
       second: '2-digit'
    });
  const columns = [
    {
      name: "Title",
      selector: row => `${ row.issue_title }`,
      sortable: true,
      grow: 2
    },
    {
      name: "Issue Id",
      selector: row => `${ row.issue_id }`,
      sortable: true,
      hide: "sm"
    },
    {
      name: "Status",
      selector: row => `${ row.issue_status }`,
      sortable: true,
      cell: row =>
        (
          <>{(row.issue_status)==='new' || (row.issue_status) === 'new-for-agent' || (row.issue_status) === 'waiting-for-agent' ? <div className="issue-status-new">{row.issue_status==="new" ? "new": "Open"/*+row.assignee_name.split(" ")[0]*/}</div>:
                                                    (row.issue_status) ==='agent-replied' ? <div className="issue-status-new">{/*row.assignee_name.split(" ")[0]+*/"Open"}</div>:
                                                                                        <div className="issue-status-rejected">{row.issue_status}</div>}</>
          
        ) 
    },
    {
      name: "Created At ("+moment.tz(zone).format("z")+")",
      selector: row => `${ row.created_at }`,
      sortable: true,
      hide: "md",
      cell: row =>
        (
          
                <>{shortcutFormatter.format(row.created_at)}</>
          
        ) 
    },
    {
      name: "Last Updated ("+moment.tz(zone).format("z")+")",
      selector: row => `${ row.updated_at }`,
      sortable: true,
      hide: "md",
      cell: row =>
        (
         
                <>{shortcutFormatter.format(row.updated_at)}</>
          
        ) 
    },
    {
      name: "",
      button: true,
      cell: row =>
        (
          <>
            <button
              onClick={() => props.click(row.issue_id)}
              style={{ marginRight: "5px",padding:'0.9em' }}
            >
              View Issue
            </button>
          </>
        ) 
    }
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const navigate = useNavigate();
  
  const handleRowClicked = row => {
    console.log(row.issue_id);
    navigate('/GetIssues?id='+row.issue_id);
    sessionStorage.setItem("renderCount",1);
   
  }
  
  useEffect(() => {
		const timeout = setTimeout(() => {
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [pending]);

  //  // Define a custom "no data" component
   const CustomNoDataComponent = () => (
    <div>
        <h6 style={{fontWeight:'bold',color:'rgba(12,180,146,255)',margin:'2em'}}>There are no open issues to display </h6>
    </div>
  );
  
  return (
    <DataTable
      title=""
      columns={columns}
      data={filteredItems}
      defaultSortField="name"
      paginationPerPage={10}
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      //progressPending={pending}
      noDataComponent={<CustomNoDataComponent/>}
      onRowClicked={handleRowClicked}
    />
  );
};

//export default Table;
