import { useEffect, useState, useRef } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { Loader } from "../components/loader";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { issueListSelector, fetchIssueListUsingToken, StoreSelectedFilter } from '../slices/IssueList'
import { fetchIssueDetails, StoreApiCallCount, StoreIsRunning } from '../slices/issues'
import SessionPoller from '../components/sessionPoller';
export const IssueContent = () => {

    const { issueList, selectedFilter } = useSelector(issueListSelector)
    //const LocalissueList =  issueList.length<=0 ? issueListJSON.parse(sessionStorage.issueList);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [issueData, setIssueData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedIssueId, setSelectedIssueId] = useState(0);
    const ref = useRef(null);
    let author = "";
    if (instance) {
        let activeAccount = instance.getActiveAccount();
        if (activeAccount.hasOwnProperty('idTokenClaims')) {
            if (activeAccount.idTokenClaims.hasOwnProperty('email')) {
                author = activeAccount.idTokenClaims.email.toUpperCase();
            }
        }
    }

    const [show, setShow] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState('All'); //This is filter value

    const handleChange = (e) => {

        setValue(e.target.value);
        dispatch(fetchIssueListUsingToken());
        const filtered = issueList.filter(issues => {

            if (e.target.value === 'myopen') {
                return issues.issue_status !== 'resolved' && issues.issue_status !== 'rejected' && issues.author_email.toUpperCase() === author;
            }
            else if (e.target.value === 'myclosed') {
                return (issues.issue_status === 'resolved' || issues.issue_status === 'rejected') && issues.author_email.toUpperCase() === author;;
            } else {
                return issues
            }
        });
        setIssueData(filtered);
    };



    function setFilter() {
        const homeFilter = issueList?.filter(issues => {
            if(!issues.author_email){
                //console.log("issue id = ",issues.issue_id);
                return false;
            }
            if (selectedFilter === "myopen") {
                return issues.issue_status !== 'resolved' && issues.issue_status !== 'rejected' && issues.author_email.toUpperCase() === author;
            } else if (selectedFilter === "myclosed") {
                console.log(selectedFilter);
                return (issues.issue_status === 'resolved' || issues.issue_status === 'rejected') && issues.author_email.toUpperCase() === author;
            } else {
                return issues;
            }
        })
        setIssueData(homeFilter);
    };

    useEffect(() => {

        // Check if the user has visited the page before
        const hasVisitedBefore = localStorage.getItem('hasVisitedPage');

        // Function to filter the issue list based on the selected filter
        function filterIssues() {
            const filtered = issueList.filter((issues) => {
                if(!issues.author_email){
                    //console.log("issue id = ",issues.issue_id);
                    return false;
                  }
                if (selectedFilter === "myopen") {
                    return (
                        issues.issue_status !== "resolved" &&
                        issues.issue_status !== "rejected" &&
                        issues.author_email.toUpperCase() === author
                    );
                } else if (selectedFilter === "myclosed") {
                    return (
                        (issues.issue_status === "resolved" ||
                            issues.issue_status === "rejected") &&
                        issues.author_email.toUpperCase() === author
                    );
                } else {
                    return issues;
                }

            });
            setIssueData(filtered);
            localStorage.setItem('hasVisitedPage', true);
        }

        if (!hasVisitedBefore) {
            console.log("hasvisited beofore ");
            // Call the filter function whenever the issueList or selectedFilter changes
            filterIssues();
        }

    }, [issueList, selectedFilter, author]);

    useEffect(()=>{
        //console.log("this is triggered");   
        //console.log("value = ",value)
        const defaultFilterData = issueList.filter((issues) => {
            if(!issues.author_email){
                //console.log("issue id = ",issues.issue_id);
                return false;
              }
            if (value ===  "myopen") {
                //console.log("myopen");
                return (
                    (issues.issue_status !== "resolved" &&
                        issues.issue_status !== "rejected") &&
                    issues.author_email.toUpperCase() === author
                );
            } else if (value === "myclosed") {
                console.log("myclosed");
                return (
                    (issues.issue_status === "resolved" ||
                        issues.issue_status === "rejected") &&
                    issues.author_email.toUpperCase() === author
                );
            } else {
                return issues;
            }
        });
        setIssueData(defaultFilterData);
    },[issueList,author,value]);




    const selectIssue = (issue_id) => {
        console.log("Issue Id from Click", issue_id);
        dispatch(fetchIssueDetails(issue_id, author));
        dispatch(StoreApiCallCount(1));
        dispatch(StoreIsRunning(true));
        setSelectedIssueId(issue_id);
        navigate(`/Getissues?id=${issue_id}`);
    };

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id')
    const element = document.getElementById(id);


    useEffect(() => {

        //dispatch(fetchIssueList())

        if (issueList?.length <= 0) {
            dispatch(fetchIssueListUsingToken());
            selectIssue(parseInt(id));
            setLoading(false);
        } else {
            setIssueData(issueList);
            setSelectedIssueId(parseInt(id));
            setLoading(false);
            setFilter();
        }
     
        const timeout = setTimeout(() => {
            setShow(true);
        }, 2000)

        element && element.scrollIntoView({ behavior: 'auto', block: "center", inline: "nearest" })
        return () => clearTimeout(timeout)
    }, [show, account]);   // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        //console.log("selected Filter", selectedFilter);
        // setFilter();
        if (!selectedFilter) {
            dispatch(StoreSelectedFilter('myopen'));
            setValue('myopen');
        } else {
            setValue(selectedFilter);
        }
  
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return <div className="posts-container">
            <Loader />
        </div>
    }

    return (
        <>
            <SessionPoller/>
            <Table>
                <thead>
                    <tr>
                        <th colSpan="3">
                            <select name="filter" value={value} onChange={handleChange} style={{ width: '100%', height: 'auto', padding: '.1em' }}>
                                <option value='All'>All Issues</option>
                                <option value='myopen'>My Open Issues</option>
                                <option value='myclosed'>My Resolved / Rejected Issues</option>
                            </select>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan="0.5">Id</th>
                        <th colSpan="1.5">Title</th>
                        <th colSpan="1">Status</th>
                        {/* <th>Agent</th> */}
                    </tr>
                </thead>
                {issueData ?
                    issueData.map(({ issue_id, issue_title, issue_status, assignee_name }) => (
                        <tbody style={{ overflow: 'scroll' }} key={issue_id}>
                            <tr key={issue_id} id={issue_id} onClick={() => selectIssue(issue_id)}
                                style={{
                                    border: selectedIssueId === issue_id ? "0px solid #ccc" : "1px solid #ccc", height: '10vh', verticalAlign: 'middle', overflow: 'hidden', fontSize: 'small',
                                    backgroundColor: selectedIssueId === issue_id ? "rgb(231, 252, 241)" : "#fff"
                                }}
                            >
                                <td colSpan="0.5">{issue_id}</td>
                                <td colSpan="1.5" ref={ref}>{issue_title}</td>
                                <td colSpan="1">{(issue_status) === 'new' || (issue_status) === 'new-for-agent' || (issue_status) === 'waiting-for-agent' ? <div className="issue-status-new">{issue_status === 'new' ? "new" : "Open"}</div> :
                                    (issue_status) === 'agent-replied' ? <div className="issue-status-new">{"Open"}</div> :
                                        <div className="issue-status-rejected">{issue_status}</div>}</td>

                                {/* <td>{!assignee_name 
                            
                            ? 
                            (issue_status === "resolved" || issue_status === "rejected" ? <div className="issue-status-rejected">Unassigned</div> : <div className="issue-status-new">Unassigned</div>)
                            : 
                            
                            // (issue_status === "resolved" || issue_status === "rejected" 
                            //     ?   <div className="issue-status-rejected">{assignee_name}</div>
                            //     :   <div className="issue-status-agent-replied">{assignee_name}</div>)
                            
                            }</td> */}
                            </tr>
                        </tbody>
                    )) : <Loader />}
            </Table>

        </>
    );
};
