import {  useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType} from '@azure/msal-browser';
import { Route, Routes} from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import { b2cPolicies} from './authConfig';
import './styles/App.css';
import { DisplayIssueTable } from './pages/landingPage';
//import { BannerDisplay } from "./components/BannerDisplay";
import { IssuesDisplay } from "./components/IssuesDisplay";
import { Helpcenter } from "./pages/Helpcenter";
import  {Form}  from "./pages/CreateIssue";
import jwt_decode from "jwt-decode";

const Pages = () => {

    const { instance} = useMsal();
    //const account = useAccount(accounts[0] || {});
    //const isAuthenticated = useIsAuthenticated();
    // const request = {
    //     ...loginRequest,
    //     account: accounts[0]
    // };
    
    useEffect(() => {
        
        if( sessionStorage.getItem("SavedToken") ){
            let token = sessionStorage.getItem("SavedToken");
            let decodedToken = jwt_decode(token);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();

            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            instance.logoutRedirect();
            //navigate("/");
            window.sessionStorage.clear();
            } else {
            console.log("Valid token");   
            //result = true;
            }
        }

        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
                
            ) 
            if (event.eventType === EventType.LOGIN_FAILURE) {
                
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                    
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return ( 
    <Routes>       
        <Route path="/home" element={<DisplayIssueTable />}/>
        <Route path="/GetIssues" element={<IssuesDisplay />}/>
        <Route path="/helpcenter" element={<Helpcenter />}/>
        <Route path="/createissue" element={<Form/>}/>
        <Route path="/" element={<DisplayIssueTable/>}/>     
    </Routes>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>           
            <PageLayout>
                <Pages />
            </PageLayout>              
        </MsalProvider>
    );
};

export default App;