import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

import { Provider} from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './slices/index.js'
//import { protectedResources } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  sessionStorage.setItem("account",msalInstance.getActiveAccount);
  localStorage.setItem("account",msalInstance.getActiveAccount);

}

msalInstance.addEventCallback((event) => {
  if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
  ) {
        msalInstance.setActiveAccount(event.payload.account);
     }
})

//Dispatch the fetchPosts() before our root component renders

const store = configureStore({ reducer: rootReducer })

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
      
        <Provider store={ store }>
            <Router>
                <App instance={msalInstance} />
            </Router>
        </Provider>
    // </React.StrictMode>
);